<template>
    <div 
        id="fh5co-features"
        v-if="records && records.length > 0"
    >
        <div class="container">
            <div class="row">
                <div 
                    class="col-md-4 animate-box"
                    v-for="(service, index) in records"
                    :key="service.id"
                >
                    <div class="feature-left">
                        <span class="icon">
                            <i :class="'fas fa-' + icons[index]"></i>
                        </span>
                        <div class="feature-copy">
                            <h3>{{ service.title }}</h3>
                            <p>{{ service.description }}</p>
                            <p>
                                <router-link
                                    :to="'/pages/' + category.slug + '/' + service.slug"
                                >
                                    Learn More
                                </router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Services',
        props:['services'], 
        computed: {
            records() {
                return this.services && this.services.services ? this.services.services : [];
            },
            category() {
                return this.services && this.services.category ? this.services.category : {};
            },
            icons() {
                return [ 'golf-ball', 'file-alt', 'wallet', 'wine-glass', 'space-shuttle', 'comment-dots' ];
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>