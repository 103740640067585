<template>
    <div id="header-slanted">
        <div class="topbar-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 topbar-contact" v-if="params">
                        <span>
                            <i class="fa fa-phone"></i> &nbsp;
                            <a 
                                :href="'tel:'+params.phone"
                            >
                                {{ params.phone }} 
                            </a>
                        </span>
                        <span>
                            <i class="fa fa-envelope"></i>&nbsp;
                            <a 
                                :href="'mailto:'+params.email">
                                {{ params.email }}
                            </a>
                        </span>
                    </div>
                    <div class="col-lg-4"></div>
                    <div class="col-lg-4 topbar-social text-center" v-if="params"> 
                        <a 
                            :href="params.twitter"
                        >
                            <i class="fa fa-twitter"></i>
                        </a>
                        <a 
                            :href="params.facebook"
                        >
                            <i class="fa fa-facebook"></i>
                        </a>
                        <a 
                            :href="params.instagram"
                        >
                            <i class="fa fa-instagram"></i>
                        </a>
                        <!-- <a 
                            :href="params.youtube"
                        >
                            <i class="fa fa-youtube"></i>
                        </a> -->
                        <a 
                            :href="whatsappUrl"
                        >
                            <i class="fa fa-whatsapp"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <header id="fh5co-heade-section" class="sticky-banner">
            <div class="container">
                <div class="nav-header">
                    <a href="#" class="js-fh5co-nav-toggle fh5co-nav-toggle dark" @click="mobileViewMenu"><i></i></a>
                    <div>
                        
                        <!-- this slanted view menu bars -->
                        <div>
                            <a 
                                v-if="isHomepage"
                                href="javascript:void(0)" 
                                id="side-menu-bar" 
                                class="side-menu-bar"
                                v-show="scroll"
                                @click="getSlantedView"
                            >
                                <i class="fa fa-bars"></i>
                            </a>
                            <!-- <router-link
                                to="/"
                                class="company-name non-mobile" v-if="params"
                            >
                                {{ params.name }}
                            </router-link> -->
                        </div>

                        <h1 id="fh5co-logo">
                            <router-link to="/">
                                <img v-lazy="'/images/logo.png'" style="height: 75px;width: 196px;" />
                            </router-link>
                        </h1>
                    </div>
                    <!-- START #fh5co-menu-wrap -->
                    <nav id="fh5co-menu-wrap" role="navigation">
                        <ul class="sf-menu" id="fh5co-primary-menu">
                            <li class="active">
                                <router-link
                                    to="/"
                                >
                                    Home
                                </router-link>
                            </li>
                            <li 
                                v-for="menu in menus"
                                :key="menu.id"
                                v-show="menu.sub_menus && menu.sub_menus.length > 0"
                            >
                                <a 
                                    href="javascript:void(0)" class="fh5co-sub-ddown"
                                >
                                   {{ menu.title }}
                                    <i class="fa fa-angle-down"></i>
                                </a>
                                <ul class="fh5co-sub-menu">
                                    <li
                                        v-for="sub_menu in menu.sub_menus"
                                        :key="sub_menu.id"
                                    >
                                        <router-link
                                            :to="'/'+menu.route + '/' +sub_menu.route"
                                        >
                                            {{ sub_menu.title }}
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li
                                v-for="menu in menus"
                                :key="menu.id"
                                v-show="menu.sub_menus && menu.sub_menus.length == 0"
                            >
                                <router-link
                                    :to="'/'+menu.route"
                                >
                                    {{ menu.title }}
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/contact">
                                    Contact
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/payments">
                                    Payments
                                </router-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
    export default {
        name: 'Header',
        props: ['getSlantedView', 'menus', 'params'],
        data() {
            return {
                scroll: true
            }
        },
        computed: {
            isHomepage() {
                return this.$route.path == '/';
            },
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            whatsappUrl() {
                if(this.params) {
                    if(this.isMobile) {
                        return 'https://api.whatsapp.com/send?phone=' + this.params.whatsapp
                    } else {
                        return '/contact#whatsapp';
                    }
                }
                return null;
            }
        },
        methods: {
            handleScroll(e) {
                this.scroll = true
                let value = window.scrollY || window.scrollTop
                return this.scroll = value && (value > 50) ? false : true;
            },
            mobileViewMenu() {
                if ( $('body').hasClass('fh5co-offcanvas') ) {
                    $('body').removeClass('fh5co-offcanvas');
                } else {
                    $('body').addClass('fh5co-offcanvas');
                }

                var $clone = $('#fh5co-menu-wrap').clone();
                $clone.attr({
                    'id' : 'offcanvas-menu'
                });
                $clone.find('> ul').attr({
                    'class' : '',
                    'id' : ''
                });

                $('#fh5co-page').prepend($clone);
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
</script>

<style lang="scss" scoped>

</style>