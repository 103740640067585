<template>
    <div 
        id="fh5co-blog-section" 
        class="fh5co-section-gray"
        v-if="records && records.length > 0"
    >
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center heading-section animate-box">
                    <h3>Flight & Tour Updates</h3>
                    <p>{{ category.description }}</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row row-bottom-padded-md">
                <div 
                    class="col-lg-4 col-md-4 col-sm-6"
                    v-for="record in records"
                    :key="record.id"
                >
                    <div class="fh5co-blog animate-box">
                        <router-link
                            :to="'/menu/' + category.route + '/' + record.slug"
                        >
                            <img 
                                v-if="record.image"
                                class="img-responsive grid-image" 
                                v-lazy="record.image.url" 
                                :alt="record.image.description"
                            >
                            <img 
                                v-if="! record.image"
                                class="img-responsive grid-image" 
                                :alt="record.title"
                            >
                        </router-link>
                        <div class="blog-text">
                            <div class="prod-title">
                                <h3>
                                    <router-link
                                        :to="'/menu/' + category.route + '/' + record.slug"
                                    >
                                        {{ record.title }}
                                    </router-link>
                                </h3>
                                <span class="posted_by">
                                    {{ formatDate(record.created_at) }}
                                </span>
                                <!-- <span class="comment"><a href="">21<i class="fa fa-comment"></i></a></span> -->
                                <p>{{ record.description }}</p>
                                <p>
                                    <router-link
                                        :to="'/menu/' + category.route + '/' + record.slug"
                                    >
                                        Read More...
                                    </router-link>
                                </p>
                            </div>
                        </div> 
                    </div>
                </div>
                <!-- <div class="clearfix visible-sm-block"></div> -->
            </div>

            <div class="col-md-12 text-center animate-box">
                <p>
                    <router-link
                        :to="'/' + category.route"
                        class="btn btn-primary btn-outline btn-lg"
                    >
                        See All Post <i class="fa fa-arrow-right"></i>
                    </router-link>
                </p>
            </div>

        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'Blog',
        props: ['blog'],
        computed: {
            formatDate(){
                return (date)=>{
                    return date ? moment(date).format('MMM Do') : 'N/A';
                }
            },
            records() {
                return this.blog && this.blog.records ? this.blog.records : [];
            },
            category() {
                return this.blog && this.blog.category ? this.blog.category : {};
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>