const rootUrl = 'https://admin.airpearl.com/api';
const siteName = 'Air Pearl Travels Limited';
// homepage Urls
const getHomepageDataUrl = rootUrl+'/get-homepage-data';
const getOtherContentPageUrl = rootUrl+'/get-other-content';
const paymentTokenUrl = rootUrl+'/get-payment-token';
const updatePaymentTransactionUrl = rootUrl+'/update-payment-transaction';
// pages urls
const getMenuPageUrl = rootUrl+'/get-menu-page';
const getOtherContentPagesUrl =  rootUrl+'/get-other-content-pages';
const getMainMenuContentUrl = rootUrl+'/get-main-menu-content';
const getMainMenuContentsUrl = rootUrl+'/get-main-menu-contents';
const getOtherContentSinglePageUrl = rootUrl+'/get-other-content-page';
const updateContactUrl = rootUrl+'/update-contact';
const getSubMenuPageContentUrl = rootUrl+'/get-sub-menu-page-contents';
const getMenuRoutePageContentUrl = rootUrl+'/get-menu-page-contents';


// Authorization Key
const authorizationToken = '2TcXrFhPN7qIRCEobdeZcY653geddeSRRmYOwvjce1';
const amadeusUrl = 'https://airpearls.amadeusonlinesuite.com';
const paginateNo = 9;
const recaptchaKey = '6LfwTpEeAAAAAGhj0MxAIAZLwARI3JWBgbB7Zkew';
// Flight keys
const agencyCode = 'A0000000054';
const userId = '2110041216280559791';
const userType = '210526104324609564';

const state = {
    getMenuRoutePageContentUrl: getMenuRoutePageContentUrl,
    getSubMenuPageContentUrl: getSubMenuPageContentUrl,
    updatePaymentTransactionUrl: updatePaymentTransactionUrl,
    paymentTokenUrl: paymentTokenUrl,
    updateContactUrl: updateContactUrl,
    getOtherContentSinglePageUrl: getOtherContentSinglePageUrl,
    getMainMenuContentsUrl: getMainMenuContentsUrl,
    getMainMenuContentUrl: getMainMenuContentUrl,
    getOtherContentPagesUrl: getOtherContentPagesUrl,
    getMenuPageUrl: getMenuPageUrl,
    getOtherContentPageUrl: getOtherContentPageUrl,
    getHomepageDataUrl: getHomepageDataUrl,
    authorizationToken: authorizationToken
    
};

const getters = {
    getsiteName: () => siteName,
    amadeusUrl: () => amadeusUrl,
    paginateNo: () => paginateNo,
    recaptchaKey: () => recaptchaKey,
    agencyCode: () => agencyCode,
    userId: () => userId,
    userType: () => userType
};

const actions = {

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}