<template>
    <!-- fh5co-blog-section -->
    <div 
        id="fh5co-testimonial" style="background-image:url(images/img_bg_1.jpg);"
        v-if="records && records.length > 0"
    >
        <div class="container">
            <div class="row animate-box">
                <div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
                    <h2>Happy Clients</h2>
                </div>
            </div>
            <div class="row">
                <div 
                    class="col-md-4"
                    v-for="record in records"
                    :key="record.id"
                >
                    <div class="box-testimony animate-box">
                        <blockquote>
                            <span class="quote"><span><i class="fa fa-quote-right"></i></span></span>
                            <span v-html="record.content"></span>
                        </blockquote>
                        <p class="author">
                            {{ record.title }}
                            <span class="subtext">
                                {{ record.description }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Testimonials',
        props: ['testimonials'],
        computed: {
            records() {
                return this.testimonials && this.testimonials.records ? this.testimonials.records : [];
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>