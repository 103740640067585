<template>
    <div>
        <div class="row" v-if="! isLoadingBlade && ! errorPage">
            <div id="main-slanted-display" class="col-lg-12">
                <div id="fh5co-wrapper">
                    <div id="fh5co-page">
                        
                        <!-- this is the header -->
                        <Header 
                            :getSlantedView="getSlantedView"
                            :menus="menus"
                            :params="params"
                        />
                        <!-- end:header-top -->
                        
                        <div id="fh5co-contact" class="fh5co-section-gray">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-8 col-md-offset-2 text-center heading-section animate-box">
                                        <h3>Contact Information</h3>
                                    </div>
                                </div>
                                <form @submit.prevent="submitContact">
                                    <div class="row animate-box">
                                        <div class="col-md-6">
                                            <span v-if="params">
                                                <h3>
                                                    Get in touch with us on WhatsApp
                                                </h3>
                                                <img 
                                                    v-lazy="'/images/whatsapp.png'" 
                                                    class="whatsapp-image"
                                                />
                                                <span class="contact-info">
                                                    <br />  
                                                    This is a chat service. <br />  
                                                    Kindly save our number 
                                                    <a 
                                                        :href="'https://api.whatsapp.com/send?phone=' + params.whatsapp" 
                                                        class="text-success"
                                                        
                                                    >
                                                        {{ params.whatsapp }}
                                                    </a>
                                                    to send us a message via WhatsApp and 
                                                    we will attend to your questions and enquiries.  <br />  <br />  
                                                    We are available 24/7  
                                                    <br />   <br /> <br />
                                                </span>
                                                <span id="whatsapp"></span>
                                            </span>
                                            <h3>Our Address</h3>
                                            <ul class="contact-info">
                                                <li>
                                                    <i class="icon-location-pin"></i>
                                                    {{ params.address }}
                                                </li>
                                                <li>
                                                    <i class="icon-phone2"></i>
                                                    <a 
                                                        :href="'tel:'+params.phone"
                                                    >
                                                        {{ params.phone }} 
                                                    </a>
                                                </li>
                                                <li>
                                                    <i class="icon-mail"></i>
                                                    <a 
                                                        :href="'mailto:'+params.email">
                                                        {{ params.email }}
                                                    </a>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <input 
                                                            type="text" 
                                                            class="form-control" 
                                                            placeholder="Name"
                                                            v-model="record.name"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <input 
                                                            type="email" 
                                                            class="form-control" 
                                                            placeholder="Email"
                                                            v-model="record.email"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <textarea 
                                                            name="" 
                                                            class="form-control" id="" 
                                                            cols="30" rows="7" 
                                                            placeholder="Message"
                                                            v-model="record.message"
                                                            required
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <button class="btn btn-primary">
                                                            {{ isloading ? 'Please Wait...' : 'Send Message' }} 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <!-- our Testimonials included here -->
                        <Testimonials 
                            :testimonials="testimonials"
                        />

                        <!-- address map included here -->
                        <iframe 
                            :src="params.gMapSrc" 
                            width="100%" height="450" 
                            style="border:0;" allowfullscreen="" 
                            loading="lazy"
                        ></iframe>

                        <!-- Footer included here -->
                        <Footer 
                            :params="params"
                            :blog="blog"
                        />
                    
                    </div>
                    <!-- END fh5co-page -->
                </div>
            <!-- END fh5co-wrapper -->
            </div>
        </div>

        <div class="row" v-if="isLoadingBlade">
            <div class="col-lg-12 text-center">
                <img src="/images/loading.gif" :alt="getsiteName">
            </div>
        </div>

        <div class="row" v-if="! isLoadingBlade && errorPage">
            <div class="col-lg-12 text-center">
                <img src="/images/error-page.gif" :alt="getsiteName">
                <h1> 
                    <em class="text-danger">
                        {{ errorMsg }}
                    </em> 
                </h1>
                <br><br>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import SlantedMenu from '../layouts/menus/SlantedMenu.vue'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import Testimonials from '../layouts/homepage/Testimonials.vue'
    import swal from 'sweetalert2'
    import { useReCaptcha } from "vue-recaptcha-v3";

    export default {    
        name: 'ContactPage',
        setup() {
            const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
            const recaptcha = async () => {
                await recaptchaLoaded();
                await executeRecaptcha("login");
            };
            return {
                recaptcha,
            };
        },
        components: {
            SlantedMenu,
            Header,
            Footer,
            Testimonials
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                blog: [],
                testimonials: [],
                record: {
                    name: null,
                    email: null,
                    message: null,
                    token: null
                },
                isloading: false
            }
        },
        computed: {
            ...mapGetters(['getsiteName', 'recaptchaKey'])
        },
        methods: {
            ...mapActions(['getHomepageData', 'updateContact']),
            submitContact() {
                this.isloading = true;
                this.$recaptcha("login").then(token => {
                    this.record.token = token;
                    this.updateContact(this.record).then((res) => {// get homepage data
                        this.isloading = false;
                        if(res.status == 200  && res.data.status) { // set data
                            this.emptyRecord();
                            swal.fire('Message Sent!', res.data.msg, 'success');
                        } else {
                            this.isLoadingBlade = false;
                            this.errorPage = true
                            this.errorMsg = res.data.msg
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    });
                }).catch(() => {
                    this.isloading = false;
                    let msg = 'Error occurred while validating request, kindly reload page and try again...';
                    swal.fire('Error Occurred', msg, 'error');
                });
            },
            emptyRecord() {
                return this.record = {
                    name: null,
                    email: null,
                    message: null,
                    token: null
                };
            },
            loadHomepage() {// load homepage data
                this.errorPage = false;
                this.getHomepageData().then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.blog = data.blog;// get blog
                this.testimonials = data.testimonials; // get testimonials
                setTimeout(() => { this.isLoadingBlade = false; }, 500)
                setTimeout(() => { this.contentWayPoint(); }, 600)
            },
            contentWayPoint() {
                var i = 0;
                $('.animate-box').waypoint( function( direction ) {
                    if( direction === 'down' && !$(this.element).hasClass('animated') ) {                        
                        i++;
                        $(this.element).addClass('item-animate');
                        setTimeout(function(){
                            $('body .animate-box.item-animate').each(function(k){
                                var el = $(this);
                                setTimeout( function () {
                                    el.addClass('fadeInUp animated');
                                    el.removeClass('item-animate');
                                },  k * 50, 'easeInOutExpo' );
                            });
                            
                        }, 100);
                    }
                } , { offset: '85%' } );
            },
            proceedSubmit(res) {
                if(res) {
                    return setTimeout(() => {
                        this.isNotRobot = ! this.isNotRobot;
                    }, 1000);
                } else {
                    swal.fire("Error Occurred", "An error occurred while verifying, kindly try again", "warning");
                }
            },
            getSlantedView() {
                $('#main-slanted-display').attr('class', 'col-lg-9 slanted-div');
                $('#side-slanted').attr('class', 'col-lg-3');
                $('#slanted-body').attr('class', 'hide-scroll');
                $('#fh5co-page').attr('class', 'slanted');
                $('#header-slanted').attr('class', 'no-slanted');
                $('#carousel-slanted').attr('class', 'carousel-inner carousel-slanted');
                $('#fh5co-features').attr('class', 'no-slanted');
                $('#fh5co-blog-section').attr('class', 'fh5co-section-gray no-slanted');
                $('#fh5co-testimonial').attr('class', 'no-slanted');
                $('#fh5co-testimonial').attr('class', 'no-slanted');
                $('#footer-slanted').attr('class', 'no-slanted');
                $('#overlay-slanted').attr('class', 'col-lg-12 btn slanted-overlay');
            },
            retunToNormalView() {
                $('#main-slanted-display').attr('class', 'col-lg-12');
                $('#side-slanted').attr('class', 'col-lg-3 no-slanted');
                $('#slanted-body').attr('class', '');
                $('#fh5co-page').attr('class', '');
                $('#header-slanted').attr('class', '');
                $('#carousel-slanted').attr('class', 'carousel-inner');
                $('#fh5co-features').attr('class', '');
                $('#fh5co-blog-section').attr('class', 'fh5co-section-gray');
                $('#fh5co-testimonial').attr('class', '');
                $('#fh5co-testimonial').attr('class', '');
                $('#footer-slanted').attr('class', '');
                $('#overlay-slanted').attr('class', 'col-lg-12 no-slanted');
            }
        },
        created() {
            this.loadHomepage(); // get page
        }
    }
</script>

<style lang="scss" scoped>
    #whatsapp {
        padding-top: 70px !important;
        margin-top: -70px !important;
    }
</style>