<template>
    <div class="container">
        <div class="row">
            <table class="table table-striped">
                <tbody>
                    <tr>
                        <td colspan="1">
                            Token:
                        </td>
                        <td colspan="3">
                            {{ record.token }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1">
                            Name:
                        </td>
                        <td colspan="3" class="text-capitalize">
                            {{ record.name }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1">
                            Email Address:
                        </td>
                        <td colspan="3">
                            {{ record.email }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1">
                            Phone Number:
                        </td>
                        <td colspan="3">
                            {{ record.phone ? record.phone : 'N/A' }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1">
                            Amount:
                        </td>
                        <td colspan="3">
                            {{ record.currency + ' ' + record.amount }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="col-md-12 text-center">
                <div class="form-group">
                    <button class="btn btn-primary" @click="asyncPay">
                       {{ isloading ? 'Please Wait...' : 'Pay Now' }} 
                    </button>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import swal from 'sweetalert2'

    export default {
        name: 'PaymentDetails',
        props: ['record', 'emptyRecord'],
        data() {
            return {
                paymentData: {
                    tx_ref: this.record.token,
                    amount: this.record.amount,
                    currency: this.record.currency,
                    payment_options: 'card,ussd',
                    redirect_url: '',
                    meta: {
                    // 'counsumer_id': '7898',
                    // 'consumer_mac': 'kjs9s8ss7dd'
                    },
                    customer: {
                        name: this.record.name,
                        email: this.record.email,
                        phone_number: this.record.phone
                    } ,
                    customizations: {
                        title: 'Air Pearl Travels Limited',
                        description: 'Payment for Booking',
                        logo: 'https://sample.airpearl.com/images/logo.png'
                    },
                    onclose: this.closedPaymentModal
                },
                isloading: false
            }
        },
        computed: {
            ...mapGetters(['getsiteName'])
        },
        methods: {
            ...mapActions(['updatePaymentTransaction']),
            asyncPay() {
                this.errorPage = false;
                this.isloading = true;
                this.asyncPayWithFlutterwave(this.paymentData).then((trans) => {
                        if(trans && (trans.status == "completed")) {// update payment transaction
                            this.updatePaymentTransaction(trans).then((res) => {
                                this.isloading = false;
                                this.emptyRecord();// remove token details
                                if(res.status == 200 && res.data.status) {                                    
                                    swal.fire('Payment Successful!', res.data.msg, 'success');
                                } else {
                                    swal.fire('Error Occurred!', res.data.msg, 'warning');
                                }
                            }).
                            catch(() => {
                                this.isloading = false;
                                let msg = 'Payment was not updated successfully, contact Support Team...';
                                swal.fire('Error Occurred!', msg, 'error');
                            });
                        }
                    }
                ).catch(() => {
                    this.emptyRecord();// remove token details
                    this.isloading = false;
                    let msg = 'Payment transaction failed, try again...';
                    swal.fire('Error Occurred!', msg, 'error');
                });                
            } ,
            closedPaymentModal() {
                console.log('payment is closed');
                this.emptyRecord();// remove token details
            },
            generateReference(){
                let date = new Date()
                return date.getTime().toString();
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>