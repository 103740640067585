<template>
    <div>
        <div class="row" v-if="! isLoadingBlade && ! errorPage">
            <div id="main-slanted-display" class="col-lg-12">
                <div id="fh5co-wrapper">
                    <div id="fh5co-page">
                        
                        <!-- this is the header -->
                        <Header 
                            :getSlantedView="getSlantedView"
                            :menus="menus"
                            :params="params"
                        />
                        <!-- end:header-top -->
                        

                        <PageTemplate
                            :record="page.record"
                            v-if="page.record"
                        />


                        <!-- Footer included here -->
                        <Footer 
                            :params="params"
                            :blog="blog"
                        />
                    
                    </div>
                    <!-- END fh5co-page -->
                </div>
            <!-- END fh5co-wrapper -->
            </div>
        </div>

        <div class="row" v-if="isLoadingBlade">
            <div class="col-lg-12 text-center">
                <img src="/images/loading.gif" :alt="getsiteName">
            </div>
        </div>

        <div class="row" v-if="! isLoadingBlade && errorPage">
            <div class="col-lg-12 text-center">
                <img src="/images/error-page.gif" :alt="getsiteName">
                <h1> 
                    <em class="text-danger">
                        {{ errorMsg }}
                    </em> 
                </h1>
                <br><br>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import SlantedMenu from '../layouts/menus/SlantedMenu.vue'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import PageTemplate from '../layouts/PageTemplate.vue'

    export default {
        name: 'MenuPage',
        components: {
            SlantedMenu,
            Header,
            Footer,
            PageTemplate
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                blog: [],
                page: {}
            }
        },
        computed: {
            ...mapGetters(['getsiteName'])
        },
        methods: {
            ...mapActions(['getHomepageData', 'getMenuPage']),
            loadPage() {
                this.errorPage = false;
                let data = { 
                    'category' : this.$route.params.category,
                    'slug' : this.$route.params.slug,
                }
                this.getMenuPage(data).then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.blog = data.blog; // set blog contents
                this.page = data.page;// get page content
                setTimeout(() => { this.isLoadingBlade = false; }, 500)
                setTimeout(() => { this.contentWayPoint(); }, 600)
            },
            contentWayPoint() {
                var i = 0;
                $('.animate-box').waypoint( function( direction ) {
                    if( direction === 'down' && !$(this.element).hasClass('animated') ) {                        
                        i++;
                        $(this.element).addClass('item-animate');
                        setTimeout(function(){
                            $('body .animate-box.item-animate').each(function(k){
                                var el = $(this);
                                setTimeout( function () {
                                    el.addClass('fadeInUp animated');
                                    el.removeClass('item-animate');
                                },  k * 50, 'easeInOutExpo' );
                            });
                            
                        }, 100);
                    }
                } , { offset: '85%' } );
            },
            getSlantedView() {
                $('#main-slanted-display').attr('class', 'col-lg-9 slanted-div');
                $('#side-slanted').attr('class', 'col-lg-3');
                $('#slanted-body').attr('class', 'hide-scroll');
                $('#fh5co-page').attr('class', 'slanted');
                $('#header-slanted').attr('class', 'no-slanted');
                $('#carousel-slanted').attr('class', 'carousel-inner carousel-slanted');
                $('#fh5co-features').attr('class', 'no-slanted');
                $('#fh5co-blog-section').attr('class', 'fh5co-section-gray no-slanted');
                $('#fh5co-testimonial').attr('class', 'no-slanted');
                $('#fh5co-testimonial').attr('class', 'no-slanted');
                $('#footer-slanted').attr('class', 'no-slanted');
                $('#overlay-slanted').attr('class', 'col-lg-12 btn slanted-overlay');
            },
            retunToNormalView() {
                $('#main-slanted-display').attr('class', 'col-lg-12');
                $('#side-slanted').attr('class', 'col-lg-3 no-slanted');
                $('#slanted-body').attr('class', '');
                $('#fh5co-page').attr('class', '');
                $('#header-slanted').attr('class', '');
                $('#carousel-slanted').attr('class', 'carousel-inner');
                $('#fh5co-features').attr('class', '');
                $('#fh5co-blog-section').attr('class', 'fh5co-section-gray');
                $('#fh5co-testimonial').attr('class', '');
                $('#fh5co-testimonial').attr('class', '');
                $('#footer-slanted').attr('class', '');
                $('#overlay-slanted').attr('class', 'col-lg-12 no-slanted');
            }
        },
        created() {
            this.loadPage(); // get page contents
        }
    }
</script>

<style lang="scss" scoped>

</style>