<template>
    <div class="container" v-if="record">
        <div class="row">
            <div class="col-md-8 col-md-offset-2 text-center heading-section animate-box">
                <h3>{{ record.title }}</h3>
                <p>{{ record.description }}</p>
            </div>
        </div>
        <div class="row row-bottom-padded-md">
            <div 
                :class="'col-md-' + (record.image && record.image.url ? 8 : 12) + ' animate-box'"
            >
                <span v-html="record.content"></span>
                <a 
                    href="javascript:void(0)"
                    v-if="record.price "
                >
                    &#8358; {{ record.price }} 
                </a>
            </div>
            <div 
                class="col-md-4 animate-box"
                v-if="record.image && record.image.url"
            >
                <img 
                    class="img-responsive grid-image" 
                    v-lazy="record.image.url" 
                    :alt="record.image.description" 
                />
            </div>

            <div 
                class="col-md-12 animate-box"
                v-if="isWhyPage && params"
            >
                <div class="row row-bottom-padded-md">
                    <div class="col-md-2 why-padding">
                        <img 
                            class="img-responsive why-us" 
                            v-lazy="'/images/trust.png'" 
                            alt="trust" 
                        />
                    </div>
                    <div class="col-md-10">
                        <h3>Trust</h3>
                        <p>{{ params.trust }}</p>
                    </div>
                </div>
                <div class="row row-bottom-padded-md">
                    <div class="col-md-2 why-padding">
                        <img 
                            class="img-responsive why-us" 
                            v-lazy="'/images/consistency.png'" 
                            alt="trust" 
                        />
                    </div>
                    <div class="col-md-10">
                        <h3>Consistency</h3>
                        <p>{{ params.consistency }}</p>
                    </div>
                </div>
                <div class="row row-bottom-padded-md">
                    <div class="col-md-2 why-padding">
                        <img 
                            class="img-responsive why-us" 
                            v-lazy="'/images/professionalism.png'" 
                            alt="trust" 
                        />
                    </div>
                    <div class="col-md-10">
                        <h3>Professionalism</h3>
                        <p>{{ params.professionalism }}</p>
                    </div>
                </div>
                <div class="row row-bottom-padded-md">
                    <div class="col-md-2 why-padding">
                        <img 
                            class="img-responsive why-us" 
                            v-lazy="'/images/customer.png'" 
                            alt="trust" 
                        />
                    </div>
                    <div class="col-md-10">
                        <h3>Customer-oriented</h3>
                        <p>{{ params.customer }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTemplate',
        props: ['record', 'params'],
        computed: {
            isWhyPage() {
                return this.$route.href == '/about-us/why-us';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .why-us {
        height: 110px !important;
    }
    .why-padding {
        margin-right: -50px;
    }
</style>