<template>
    <div v-if="menu">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center heading-section animate-box">
                    <h3>{{ menu.title }}</h3>
                    <p>{{ menu.description }}</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row row-bottom-padded-md" v-if="records && records.length > 0">
                <div 
                    class="col-lg-4 col-md-4 col-sm-6"
                    v-for="record in records"
                    :key="record.id"
                >
                    <div class="fh5co-blog animate-box">
                        <router-link
                            :to="'/menu/' + menu.route + '/' + record.slug"
                        >
                            <img 
                                v-if="record.image"
                                class="img-responsive grid-image" 
                                v-lazy="record.image.url" 
                                :alt="record.image.description" 
                            />
                        </router-link>
                        <div class="blog-text">
                            <div class="prod-title">
                                <h3>
                                    <router-link
                                        :to="'/menu/' + menu.route + '/' + record.slug"
                                    >
                                        {{ record.title }}
                                    </router-link>
                                </h3>
                                <span class="posted_by" v-if="isBlog">
                                    {{ formatDate(record.created_at) }}
                                </span>
                                <p v-if="isBlog">{{ record.description }}</p>
                                <p v-if="isBlog">
                                    <router-link
                                        :to="'/menu/' + menu.route + '/' + record.slug"
                                    >
                                        Read More...
                                    </router-link>
                                </p>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            <Pagination 
                :pagination="pagination"
                :paginate="paginate"
            />

            
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import Pagination from '../layouts/Pagination.vue'
    
    export default {
        name: 'GridTemplate',
        props: ['menu', 'contents', 'paginate'],
        components: {
            Pagination
        },
        computed: {
            formatDate(){
                return (date)=>{
                    return date ? moment(date).format('MMM Do') : 'N/A';
                }
            },
            records() {
                return this.contents.data;
            },
            pagination() {
                let data = this.contents;
                return {
                    hasPagination: (data.prev_page_url || data.next_page_url),
                    links: data.links
                }
            },
            isBlog() {// is blog contents
                return this.menu && this.menu.route && (this.menu.route == 'blog');
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>