import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueReCaptcha } from "vue-recaptcha-v3"
import Flutterwave from  'flutterwave-vue-v3'
import lazyPlugin from 'vue3-lazy'

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueReCaptcha, { siteKey: "6LfwTpEeAAAAAGhj0MxAIAZLwARI3JWBgbB7Zkew" })
// app.use(Flutterwave, { publicKey: "FLWPUBK_TEST-8ca169d56c07e44bd63f62723e75166b-X" })
app.use(Flutterwave, { publicKey: "FLWPUBK-2d17d6c5754785ca0943da3a9d225ddd-X" })
app.use(lazyPlugin, {
    loading: 'loading.png',
    error: 'error.png'
  })
app.mount('#app')
