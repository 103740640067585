<template>
    <div class="fh5co-hero">
        <div class="fh5co-cover" id="banner">
            <div 
                id="carouselExampleIndicators" 
                class="carousel slide" 
                data-ride="carousel"
                v-if="sliders && sliders.length > 0"
            >
                <ol class="carousel-indicators">
                    <li 
                        data-target="#carouselExampleIndicators" 
                        :data-slide-to="index" 
                        :class="(index == 0 ? 'active' : '')"
                        v-for="(slider, index) in sliders"
                        :key="slider.id"
                    ></li>
                </ol>
                <div id="carousel-slanted" class="carousel-inner">
                    <div 
                        :class="'carousel-item ' + (index == 0 ? 'active' : '')" 
                        v-for="(slider, index) in sliders"
                        :key="slider.id"
                    >
                        <img 
                            v-lazy="slider.image.url"
                            :alt="slider.image.description"
                            style="width: 100%; height: 85%"
                        />
                        
                    </div>
                </div>
            </div>

            <!-- the flight and hotel panels are included here  -->
            <FlightPanel />

        </div>
    </div>
</template>

<script>
    import FlightPanel from './FlightPanel.vue'

    export default {
        name: 'Slider',
        props: ['sliders'],
        components: {
            FlightPanel
        },
        methods: {
            slideShow() {
                setInterval(function(){ 
                    $('.carousel').carousel('next');
                }, 5000);
            }
        },
        created() {
            this.slideShow();
        }
    }
</script>

<style lang="scss" scoped>

</style>