<template>
    <div role="tabpanel" class="tab-pane" id="hotels">
        <div class="row non-mobile">
            <div class="col-lg-12 col-lg-12 flight-button-box">
                <div class="btn-group">
                    <button 
                        class="btn btn-danger btn-sm dropdown-toggle flight-button" 
                        type="button" data-toggle="dropdown" 
                        aria-haspopup="true" aria-expanded="false"
                    >
                    {{ rooms }}, {{ guest }}
                    </button>
                    <div class="dropdown-menu" onclick="event.stopPropagation();">
                        <div class="full-passenger">
                            <div class="row passenger">
                                <div 
                                    class="row passenger"
                                    v-for="(num, index) in record.room"
                                    :key="num"
                                >
                                    <div class="col-12">
                                        <p class="text-dark mb-0 font-weight-bold">
                                            Room {{ index+1 }} 
                                            <a 
                                                href="javascript:void(0)" 
                                                @click="addRoom(false, index)"
                                                v-if="index > 0"
                                            >
                                                <em class="text-primary pull-right">
                                                    Remove
                                                </em>
                                            </a>
                                            
                                        </p>
                                        <hr>
                                    </div>
                                    <div class="col-6">
                                        <p class="text-dark mb-0 font-weight-bold">
                                            Adults
                                        </p>
                                        <p class="text-dark font-12 mb-0">
                                            > 12 years
                                        </p>
                                    </div>
                                    <div class="col-6">
                                        <button 
                                            class="btn btn-sm minus-btn"
                                            :disabled="num.adult <= 1"
                                            @click="addOccupant('minus', 'adult', num)"
                                        >
                                            <i class="fa fa-minus"></i>
                                        </button>
                                        <input type="text" class="passenger-inupt" :value="num.adult">
                                        <button 
                                            class="btn btn-sm minus-btn"
                                            @click="addOccupant('plus', 'adult', num)"
                                        >
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div class="col-12 gap"></div>
                                    <div class="col-6">
                                        <p class="text-dark mb-0 font-weight-bold">
                                            Children
                                        </p>
                                        <p class="text-dark font-12 mb-0">
                                            2 - 12 years
                                        </p>
                                    </div>
                                    <div class="col-6">
                                        <button 
                                            class="btn btn-sm minus-btn"
                                            :disabled="num.children <= 0"
                                            @click="addOccupant('minus', 'children', num)"
                                        >
                                            <i class="fa fa-minus"></i>
                                        </button>
                                        <input type="text" class="passenger-inupt" :value="num.children">
                                        <button 
                                            class="btn btn-sm minus-btn"
                                            @click="addOccupant('plus', 'children', num)"
                                        >
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                
                                <div class="col-12 gap"></div>
                                <div class="col-12">
                                    <button 
                                        class="btn room-button" 
                                        style="border: 1px solid #000 !important;"
                                        @click="addRoom(true)"
                                    >
                                        ADD ROOM {{ record.room.length + 1 }} +
                                    </button>
                                    <button 
                                        class="btn done-button btn-primary pull-right"
                                        @click="hidePassengerPanel()"
                                    >
                                        DONE
                                    </button>
                                </div>
                            </div>
                        </div>														
                    </div>
                </div>
            </div>
        </div>

        <div class="row flight-box">
            <div class="col-xxs-4 col-xs-4 mt round-edges non-mobile">
                <div class="input-field">
                    <label for="from">Going to?</label>
                    <Autocomplete
                        v-if="! showValue"
                        placeholder="Destination"
                        @input="getCity"
                        :results="searchCities"
                        :display-item="formattedDisplay"
                        @onSelect="selectedCity"
                        
                        :max="50"
                    ></Autocomplete>
                    <input 
                        type="text" 
                        class="form-control" 
                        id="from-place" 
                        :value="showValue" 
                        v-if="showValue"
                        @click="hideTripValue()"
                    />
                </div>
            </div>
            <div class="col-lg-12 mt round-edges mobile">
                <div class="input-field">
                    <label for="from">Going to?</label>
                    <Autocomplete
                        v-if="! showValue"
                        placeholder="Destination"
                        @input="getCity"
                        :results="searchCities"
                        :display-item="formattedDisplay"
                        @onSelect="selectedCity"
                        
                        :max="50"
                    ></Autocomplete>
                    <input 
                        type="text" 
                        class="form-control" 
                        id="from-place" 
                        :value="showValue" 
                        v-if="showValue"
                        @click="hideTripValue()"
                    />
                </div>
            </div>
            <div class="col-xxs-2 col-xs-2 mt round-edges non-mobile">
                <div class="input-field">
                    <label for="from">
                        <i class="fa  fa-calendar"></i>
                        &nbsp;&nbsp; Check-in
                    </label>
                    <flat-pickr 
                        class="form-control"
                        v-model="record.check_in"
                        :config="config"
                    ></flat-pickr>
                </div>
            </div>
            <div class="col-lg-12 mt round-edges mobile">
                <div class="input-field">
                    <label for="from">
                        <i class="fa  fa-calendar"></i>
                        &nbsp;&nbsp; Check-in
                    </label>
                    <flat-pickr 
                        class="form-control"
                        v-model="record.check_in"
                        :config="config"
                    ></flat-pickr>
                </div>
            </div>
            <div class="col-xxs-2 col-xs-2 mt round-edges non-mobile">
                <div class="input-field">
                    <label for="from">
                        <i class="fa  fa-calendar"></i>
                        &nbsp;&nbsp; Check-out
                    </label>
                    <flat-pickr 
                        class="form-control"
                        v-model="record.check_out"
                        :config="config"
                    ></flat-pickr>
                </div>
            </div>
            <div class="col-lg-12 mt round-edges mobile">
                <div class="input-field">
                    <label for="from">
                        <i class="fa  fa-calendar"></i>
                        &nbsp;&nbsp; Check-out
                    </label>
                    <flat-pickr 
                        class="form-control"
                        v-model="record.check_out"
                        :config="config"
                    ></flat-pickr>
                </div>
            </div>
            <div class="col-xxs-2 col-xs-2 non-mobile">
                <!-- <input type="submit" class="btn btn-search btn-block" value="Search"> -->
                <button type="button" 
                    class="btn btn-search btn-block"
                    @click="searchForHotel"
                >
                    <i class="fas fa-search"></i> &nbsp;
                    Search														
                </button>
            </div>

            <div class="col-lg-12 mt mobile">
                <button 
                    class="btn btn-danger btn-block dropdown-toggle passenger-mobile" 
                    type="button" data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false"
                >
                    {{ rooms }}, {{ guest }}
                </button>
                <div class="dropdown-menu" onclick="event.stopPropagation();">
                    <div class="">
                        <div class="row passenger">
                            <div 
                                class="row passenger"
                                v-for="(num, index) in record.room"
                                :key="num"
                            >
                                <div class="col-12">
                                    <p class="text-dark mb-0 font-weight-bold">
                                        Room {{ index+1 }} 
                                        <a 
                                            href="javascript:void(0)" 
                                            @click="addRoom(false, index)"
                                            v-if="index > 0"
                                        >
                                            <em class="text-primary pull-right">
                                                Remove
                                            </em>
                                        </a>
                                    </p>
                                    <hr>
                                </div>
                                <div class="col-6">
                                    <p class="text-dark mb-0 font-weight-bold">
                                        Adults
                                    </p>
                                    <p class="text-dark font-12 mb-0">
                                        > 12 years
                                    </p>
                                </div>
                                <div class="col-6">
                                    <button 
                                        class="btn btn-sm minus-btn"
                                        :disabled="num.adult <= 1"
                                        @click="addOccupant('minus', 'adult', num)"
                                    >
                                        <i class="fa fa-minus"></i>
                                    </button>
                                    <input type="text" class="passenger-inupt" :value="num.adult">
                                    <button 
                                        class="btn btn-sm minus-btn"
                                        @click="addOccupant('plus', 'adult', num)"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                                <div class="col-12 gap"></div>
                                <div class="col-6">
                                    <p class="text-dark mb-0 font-weight-bold">
                                        Children
                                    </p>
                                    <p class="text-dark font-12 mb-0">
                                        2 - 12 years
                                    </p>
                                </div>
                                <div class="col-6">
                                    <button 
                                        class="btn btn-sm minus-btn"
                                        :disabled="num.children <= 0"
                                        @click="addOccupant('minus', 'children', num)"
                                    >
                                        <i class="fa fa-minus"></i>
                                    </button>
                                    <input type="text" class="passenger-inupt" :value="num.children">
                                    <button 
                                        class="btn btn-sm minus-btn"
                                        @click="addOccupant('plus', 'children', num)"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            
                            <div class="col-12 gap"></div>
                            <div class="col-12">
                                <button 
                                    class="btn room-button" 
                                    style="border: 1px solid #000 !important;"
                                    @click="addRoom(true)"
                                >
                                    ADD ROOM {{ record.room.length + 1 }} +
                                </button>
                                <button 
                                    class="btn done-button btn-primary pull-right"
                                    @click="hidePassengerPanel()"
                                >
                                    DONE
                                </button>
                            </div>
                        </div>
                    </div>														
                </div>
            </div>

            <div class="col-lg-12 mobile">
                <button type="button" 
                    class="btn btn-search btn-block search-mobile-button"
                    @click="searchForHotel"
                >
                    <i class="fas fa-search"></i> &nbsp;
                    Search
                </button>
            </div>

            <div class="col-xxs-12 col-xs-12 margin-top-20">
                <span class="pull-right">
                    <i class="fab fa-app-store"></i>
                    Affordability guaranteed
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import states from '../../../../states.json'
    import countries from '../../../../countries.json'
    import Autocomplete from 'vue3-autocomplete'
    import 'vue3-autocomplete/dist/vue3-autocomplete.css'
    import flatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'
    import moment from 'moment'
    import swal from 'sweetalert2'

    export default {
        name: 'Hotels',
        components: {
            flatPickr,
            Autocomplete
        },
        data() {
            return {
                record: {
                    room: [
                        { adult: 1, children: 0 }
                    ],
                    city: null,
                    check_in: moment().format('YYYY-MM-DD'),
                    check_out: moment().add(5, 'day').format('YYYY-MM-DD')
                },
                states: states,
                countries: countries,
                showValue: null,
                searchCities: [],
                config: {
                    altFormat: 'M j, Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    minDate: "today" 
                }
            }
        },
        computed: {
            guest() {
                let total = 0;
                for(let room of this.record.room) {
                    total += room.adult;
                    total += room.children;
                }
                return total + (total > 1 ? ' Guests' : ' Guest');
            },
            rooms() {
                let count = this.record.room.length;
                let text = count > 1 ? 's' : '';
                return count +' Room' + text;
            },
            getUrlFormat() {// get flight url
                let url = '/hotel/search?';
                return url;
            },
        },
        methods: {
            searchForHotel() {
                if(this.record.city) {
                    return window.location.href = this.getUrlFormat;
                } else {
                    let msg = 'You have not selected Destination';
                    swal.fire("Invalid Inputs!", msg, "warning");
                }
            },
            hideTripValue() {
                this.searchCities = [];
                this.showValue = null;
                this.record.city = null;
            },
            getCity(e) {
                this.searchCities = [];
                if(e && e.length >= 3) {
                    let key = e.toLowerCase();
                    let records = this.states.filter(item => item.name.toLowerCase().indexOf(key) !== -1 );
                    for(let record of records) {
                        let country = this.countries.filter(item => item.id == record.country_id);
                        record.country = country && country.length > 0 ? country[0].name : null;
                        this.searchCities.push(record);
                    }
                }
            },
            selectedCity(e) {
                this.showValue = null;
                this.record.city = null;
                if(e && e.name) {
                    this.record.city = e.name;
                    this.showValue = e.name + ', ' + e.country; 
                } 
                this.searchCities = [];
            },
            formattedDisplay(e) {
                return e.name + ', ' + e.country; 
            },
            addRoom(status, index = null) {
                let newData = { adult: 1, children: 0 };
                if(status) {
                    return this.record.room.push(newData)
                } else {
                    return this.record.room.splice(index, 1);
                }
            },
            addOccupant(status, type, data) {
                if(type == 'adult') {// update adult occupant
                    if(status == 'plus') {
                        data.adult++;
                    } else {
                        (data.adult > 1) ? data.adult-- : false;
                    }
                } else {// update children occupant
                    if(status == 'plus') {
                        data.children++;
                    } else {
                        (data.children > 0) ? data.children-- : false;
                    }
                }
            },
            hidePassengerPanel() {
                $('.dropdown.show,.dropdown-menu.show').removeClass('show');
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>