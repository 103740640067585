<template>
    <div id="side-slanted" class="col-lg-3 no-slanted" style="overflow-y: scroll;">
        <ul class="side-menu">
            <li 
                v-for="menu in menus"
                :key="menu.id"
            >
                <router-link
                    :to="getExactUrl(menu)"
                >
                    {{ menu.title }}
                </router-link>
            </li> 
            <li>
                <router-link to="/contact">
                    Contact
                </router-link>
            </li>
            <li>
                <router-link to="/payments">
                    Payments
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'SlantedMenu',
        props: ['menus'],
        methods: {
            getExactUrl(item) {
                let isSubMenu = item && item.menu_id ? true : false;
                if(isSubMenu) {// url for sub menus
                    return '/'+item.menu.route + '/' +item.route;
                }
                return '/' +item.route;
            }
        }
    }
</script>