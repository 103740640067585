<template>
    <div 
        class="row row-bottom-padded-md animate-box"
        v-if="pagination.hasPagination"
    >
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                <li 
                    :class="'page-item ' + (link.url ? '' : 'disabled') 
                            + (link.active ? ' active' : '')"
                    v-for="(link, index) in pagination.links"
                    :key="index"
                >
                    <span 
                        class="page-link"
                        v-if="index == 0"
                        @click="paginate(link.url)"
                    >&laquo;</span>
                    <span 
                        class="page-link"
                        v-if="((index != 0) && (index+1 != pagination.links.length))"
                        @click="paginate(link.url)"
                    >{{ link.label }}</span>
                    <span 
                        class="page-link"
                        v-if="(index+1 == pagination.links.length)"
                        @click="paginate(link.url)"
                    >&raquo;</span>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    export default {
        name: 'Pagination',
        props: ['paginate', 'pagination']
    }
</script>

<style lang="scss" scoped>

</style>