<template>
    <div 
        id="fh5co-tours" class="fh5co-section-gray"
        v-if="destinations && destinations.length > 0"
    >
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center heading-section animate-box">
                    <h3>{{ category.title }}</h3>
                    <p>{{ category.description }}</p>
                </div>
            </div>
            <div class="row">
                <div 
                    class="col-md-4 col-sm-6 fh5co-tours animate-box" 
                    data-animate-effect="fadeIn"
                    v-for="record in destinations"
                    :key="record.id"
                >
                    <div href="#">
                        <img 
                            v-lazy="record.image.url" 
                            :alt="record.image.description" 
                            class="img-responsive grid-image"
                        >
                        <div class="desc">
                            <span></span>
                            <h3>{{ record.title }}</h3>
                            <span>{{ record.description }}</span>
                            <span class="price">
                                <b v-html="getCurrencySign(record.currency)"></b>
                                {{ record.price }}
                            </span>
                            <router-link
                                :to="'/pages/' + category.slug + '/' + record.slug"
                                class="btn btn-primary btn-outline"
                            >
                                Book Now <i class="fa fa-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 text-center animate-box" v-if="type">
                    <p>
                        <router-link
                            :to="'/category/' + category.slug"
                            class="btn btn-primary btn-outline btn-lg"
                        >
                            See All Offers <i class="fa fa-arrow-right"></i>
                        </router-link>
                    </p>
                </div>
            </div>

            <Pagination 
                :pagination="pagination"
                :paginate="paginate"
            />

        </div>
    </div>
</template>

<script>
    import Pagination from '../../layouts/Pagination.vue'

    export default {
        name: 'TopDestination',
        props: ['topDestinations', 'type', 'checkPaginate', 'paginate'],
        components: {
            Pagination
        },
        computed: {
            destinations() {
                if(this.checkPaginate) {
                    return this.topDestinations && this.topDestinations.destinations ?
                            this.topDestinations.destinations.data : [];
                } else {
                    return this.topDestinations && this.topDestinations.destinations ?
                        this.topDestinations.destinations : [];
                }                
            },
            category() {
                return this.topDestinations && this.topDestinations.category ?
                            this.topDestinations.category : {};
            },
            pagination() {
                let data = this.topDestinations && this.topDestinations.destinations ?
                                this.topDestinations.destinations : {};
                return {
                    hasPagination: (data.prev_page_url || data.next_page_url),
                    links: data.links
                }
            }
        },
        methods: {
            getCurrencySign(currency) {
                if(currency == 'NGN') {
                    return '&#8358;'
                } else if(currency == 'USD') {
                    return '&#x24;';
                } else {
                    return '&#x20AC;';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>