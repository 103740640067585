<template>
    <div role="tabpanel" class="tab-pane active" id="flights">
        <div class="row">
            <div class="col-lg-12 col-lg-12 flight-button-box non-mobile">
                <div class="btn-group">
                    &nbsp;
                    <button 
                        class="btn btn-danger btn-sm dropdown-toggle flight-button" 
                        type="button" data-toggle="dropdown" 
                        aria-haspopup="true" aria-expanded="false"
                    >
                        {{ trip.title }}
                    </button>
                    <div class="dropdown-menu">
                        <a 
                            class="dropdown-item" 
                            href="javascript:void(0)"
                            v-for="(item, index) in tripData"
                            :key="index"
                            @click="changeTripData(item)"
                        >
                            <i class="fa fa-check" v-if="item.selected"></i>
                            <span :style="'margin-left: ' +(item.selected ? '7px' : '20px')+';'">
                                {{ item.title }}
                            </span>
                        </a>
                    </div>
                </div>

                <div class="btn-group">
                    &nbsp;
                    <button 
                        class="btn btn-danger btn-sm dropdown-toggle flight-button" 
                        type="button" data-toggle="dropdown" 
                        aria-haspopup="true" aria-expanded="false"
                    >
                        {{ totalPassengers }}
                    </button>
                    <div class="dropdown-menu"  onclick=" event.stopPropagation();">
                        <div class="full-passenger">
                            <div class="row passenger">
                                <div class="col-6">
                                    <p class="text-dark mb-0 font-weight-bold">
                                        Adults
                                    </p>
                                    <p class="text-dark font-12 mb-0">
                                        > 12 years
                                    </p>
                                </div>
                                <div class="col-6">
                                    <button 
                                        class="btn btn-sm minus-btn"
                                        :disabled="passenger.adult <= 1"
                                        @click="addPassenger('minus', 'adult')"
                                    >
                                        <i class="fa fa-minus"></i>
                                    </button>
                                    <input 
                                        type="text" 
                                        class="passenger-inupt" 
                                        v-model="passenger.adult"
                                    />
                                    <button 
                                        class="btn btn-sm minus-btn"
                                        :disabled="passenger.adult == 9"
                                        @click="addPassenger('plus', 'adult')"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                                <div class="col-12 gap"></div>
                                <div class="col-6">
                                    <p class="text-dark mb-0 font-weight-bold">
                                        Children
                                    </p>
                                    <p class="text-dark font-12 mb-0">
                                        2 - 12 years
                                    </p>
                                </div>
                                <div class="col-6">
                                    <button 
                                        class="btn btn-sm minus-btn"
                                        :disabled="passenger.children <= 0"
                                        @click="addPassenger('minus', 'children')"
                                    >
                                        <i class="fa fa-minus"></i>
                                    </button>
                                    <input 
                                        type="text" 
                                        class="passenger-inupt" 
                                        v-model="passenger.children"
                                    />
                                    <button 
                                        class="btn btn-sm minus-btn"
                                        :disabled="passenger.children == 9"
                                        @click="addPassenger('plus', 'children')"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                                <div class="col-12 gap"></div>
                                <div class="col-6">
                                    <p class="text-dark mb-0 font-weight-bold">
                                        Infants
                                    </p>
                                    <p class="text-dark font-12 mb-0">
                                        &lt; 2 years
                                    </p>
                                </div>
                                <div class="col-6">
                                    <button 
                                        class="btn btn-sm minus-btn"
                                        :disabled="passenger.infants <= 0"
                                        @click="addPassenger('minus', 'infants')"
                                    >
                                        <i class="fa fa-minus"></i>
                                    </button>
                                    <input 
                                        type="text" 
                                        class="passenger-inupt" 
                                        v-model="passenger.infants"
                                    />
                                    <button 
                                        class="btn btn-sm minus-btn"
                                        :disabled="passenger.infants == 9"
                                        @click="addPassenger('plus', 'infants')"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                                <div class="col-12 gap"></div>
                                <div class="col-12">
                                    <button 
                                        class="btn done-button btn-primary pull-right"  
                                        @click="hidePassengerPanel()"
                                    >
                                        DONE
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="btn-group">
                    <button 
                        class="btn btn-danger btn-sm dropdown-toggle flight-button" 
                        type="button" data-toggle="dropdown" 
                        aria-haspopup="true" aria-expanded="false"
                    >
                        {{ type.title }}
                    </button>
                    <div class="dropdown-menu">
                        <a 
                            class="dropdown-item" 
                            href="javascript:void(0)"
                            v-for="(type, index) in typeData"
                            :key="index"
                            @click="changeTypeData(type)"
                        >
                            <i class="fa fa-check" v-if="type.selected"></i>
                            <span :style="'margin-left: ' +(type.selected ? '7px' : '20px')+';'">
                                {{ type.title }}
                            </span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-lg-12 flight-button-box mobile">
                <button type="button" 
                    :class="'btn btn-primary text-uppercase mobile-flight-' + (item.selected ? 'active' : '')" 
                    v-for="(item, index) in tripData"
                    :key="index"
                    @click="changeTripData(item)"
                >
                    {{ item.title }}&nbsp;
                </button>
            </div>
        </div>


        <!-- non mobile flight form -->
        <div class="row non-mobile">
            <div 
                class="row flight-box"
                v-for="(num, index) in mulitTrips"
                :key="num"
            >
                <div class="col-xxs-2 col-xs-2 mt round-edges">
                    <div class="input-field">
                        <label for="from">From where?</label>
                        <!-- the departure autocomplete -->
                        <Autocomplete
                            v-if="! showDeptValue[index]"
                            placeholder="Origin City"
                            @input="getAirport"
                            :results="searchAirports"
                            :display-item="formattedDisplay"
                            @onSelect="selectedDeptAirport($event, index)"
                            
                            :max="50"
                        ></Autocomplete>
                        <input 
                            type="text" 
                            class="form-control" 
                            id="from-place" 
                            :value="showDeptValue[index].value" 
                            v-if="showDeptValue[index] && showDeptValue[index].value"
                            @click="hideTripValue('dept', index)"
                        />
                    </div>
                </div>
                <div class="col-xxs-2 col-xs-2 mt round-edges">
                    <div class="input-field">
                        <label for="from">To where?</label>
                        <!-- <input type="text" class="form-control" id="to-place" placeholder="London, England"/> -->
                        <!-- the arrival autocomplete -->
                        <Autocomplete
                            v-if="! showReturnValue[index]"
                            placeholder="Destination City"
                            @input="getReturnAirport"
                            :results="searchReturnAirports"
                            :display-item="formattedDisplay"
                            @onSelect="selectedReturnAirport($event, index)"
                            
                            :max="50"
                        ></Autocomplete>
                        <input 
                            type="text" 
                            class="form-control" 
                            id="from-place" 
                            :value="showReturnValue[index].value" 
                            v-if="showReturnValue[index] && showReturnValue[index].value"
                            @click="hideTripValue('return', index)"
                        />
                    </div>
                </div>
                <div class="col-xxs-4 col-xs-4 mt round-edges">
                    <div class="input-field">
                        <div class="row">
                            <div class="col-lg-6">
                                <label for="from">
                                    <i class="fa  fa-calendar"></i>
                                    &nbsp;&nbsp; Leaving on 
                                </label>
                                <flat-pickr 
                                    class="form-control"
                                    v-model="record.date[index]"
                                    :config="config"
                                ></flat-pickr>
                            </div>
                            <div class="col-lg-6 return-panel" v-if="showRoundTripDate">
                                <label for="from">
                                    <i class="fa  fa-calendar"></i>
                                    &nbsp;&nbsp; Returning on
                                </label>
                                <flat-pickr 
                                    class="form-control"
                                    v-model="return_date"
                                    :config="config"
                                ></flat-pickr>
                            </div>
                            <div class="col-lg-6" v-if="! showRoundTripDate">
                                <label for="from">
                                </label>
                                <flat-pickr 
                                    class="form-control"
                                    disabled
                                    :config="config"
                                ></flat-pickr>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xxs-2 col-xs-2">
                    <button 
                        type="button" 
                        class="btn btn-search btn-block" v-if="index == 0"
                        @click="searchForFlight"
                    >													
                        Let's go
                        <i class="fab fa-accessible-icon"></i>
                    </button>

                    <button 
                        type="button" 
                        class="btn btn-search btn-block" 
                        v-if="index > 0"
                        @click="addAnotherTrip(false)"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            
            <div 
                class="col-xxs-4 col-xs-4 flight-add-button margin-top-20"
                v-if="(trip.value == 3)"
            >
                <button type="button" class="btn btn-sm btn-add-button" @click="addAnotherTrip(true)">													
                    Add another flight
                </button>
            </div>

            <div 
                :class="((trip.value == 3) ? 'col-xxs-7 col-xs-7' : 'col-xxs-12 col-xs-12')+ ' margin-top-20'"
            >
                <span class="pull-right">
                    <i class="fab fa-app-store"></i>
                    Affordability guaranteed
                </span>
            </div>
        </div>
        <!-- ends here -->

        <div class="row mobile">
            <div 
                class="row flight-box"
                v-for="(num, index) in mulitTrips"
                :key="num"
            >
                <div class="col-lg-12 mt round-edges">
                    <div class="input-field">
                        <label for="from" style="width: 100%;">From where?</label>
                        <Autocomplete
                            v-if="! showDeptValue[index]"
                            placeholder="Origin City"
                            @input="getAirport"
                            :results="searchAirports"
                            :display-item="formattedDisplay"
                            @onSelect="selectedDeptAirport($event, index)"
                            
                            :max="50"
                        ></Autocomplete>
                        <input 
                            type="text" 
                            class="form-control" 
                            id="from-place" 
                            :value="showDeptValue[index].value" 
                            v-if="showDeptValue[index] && showDeptValue[index].value"
                            @click="hideTripValue('dept', index)"
                        />
                    </div>
                </div>
                <div class="col-lg-12 mt round-edges">
                    <div class="input-field">
                        <label for="from" style="width: 100%;">To where?</label>
                        <Autocomplete
                            v-if="! showReturnValue[index]"
                            placeholder="Destination City"
                            @input="getReturnAirport"
                            :results="searchReturnAirports"
                            :display-item="formattedDisplay"
                            @onSelect="selectedReturnAirport($event, index)"
                            
                            :max="50"
                        ></Autocomplete>
                        <input 
                            type="text" 
                            class="form-control" 
                            id="from-place" 
                            :value="showReturnValue[index].value" 
                            v-if="showReturnValue[index] && showReturnValue[index].value"
                            @click="hideTripValue('return', index)"
                        />
                    </div>
                </div>
                <div class="col-lg-12 mt round-edges">
                    <div class="input-field">
                        <label for="from" style="width: 100%;">
                            <i class="fa  fa-calendar"></i>
                            &nbsp;&nbsp; Leaving on 
                        </label>
                        <flat-pickr 
                            class="form-control"
                            v-model="record.date[index]"
                            :config="config"
                        ></flat-pickr>
                    </div>
                </div>
                <div class="col-lg-12 mt round-edges" v-if="showRoundTripDate">
                    <div class="input-field">
                        <label for="from" style="width: 100%;">
                            <i class="fa  fa-calendar"></i>
                            &nbsp;&nbsp; Returning on
                        </label>
                        <flat-pickr 
                            class="form-control"
                            v-model="return_date"
                            :config="config"
                        ></flat-pickr>
                    </div>
                </div>

                <span 
                    class="remove-btn"
                    v-if="index > 0"
                    @click="addAnotherTrip(false)"
                >
                    Remove
                </span>

            </div>

            <div 
                class="col-lg-12 text-center" 
                style="margin: 10px 0px;"
                v-if="(trip.value == 3)"
            >
                <button type="button" class="btn btn-sm btn-add-button btn-block" @click="addAnotherTrip(true)">													
                    Add another flight
                </button>
            </div>

            <div class="col-lg-12 mt">
                <button 
                    class="btn btn-danger btn-block dropdown-toggle passenger-mobile" 
                    type="button" data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false"
                >
                    {{ totalPassengers }}
                </button>
                <div class="dropdown-menu" onclick=" event.stopPropagation();">
                    <div class="passenger">
                        <div class="row passenger">
                            <div class="col-6">
                                <p class="text-dark mb-0 font-weight-bold">
                                    Adults
                                </p>
                                <p class="text-dark font-12 mb-0">
                                    > 12 years
                                </p>
                            </div>
                            <div class="col-6">
                                <button 
                                    class="btn btn-sm minus-btn"
                                    :disabled="passenger.adult <= 1"
                                    @click="addPassenger('minus', 'adult')"
                                >
                                    <i class="fa fa-minus"></i>
                                </button>
                                <input 
                                    type="text" 
                                    class="passenger-inupt" 
                                    v-model="passenger.adult"
                                />
                                <button 
                                    class="btn btn-sm minus-btn"
                                    :disabled="passenger.adult == 9"
                                    @click="addPassenger('plus', 'adult')"
                                >
                                    <i class="fa fa-plus"></i>
                                </button>
                            </div>
                            <div class="col-12 gap"></div>
                            <div class="col-6">
                                <p class="text-dark mb-0 font-weight-bold">
                                    Children
                                </p>
                                <p class="text-dark font-12 mb-0">
                                    2 - 12 years
                                </p>
                            </div>
                            <div class="col-6">
                                <button 
                                    class="btn btn-sm minus-btn"
                                    :disabled="passenger.children <= 0"
                                    @click="addPassenger('minus', 'children')"
                                >
                                    <i class="fa fa-minus"></i>
                                </button>
                                <input 
                                    type="text" 
                                    class="passenger-inupt" 
                                    v-model="passenger.children"
                                />
                                <button 
                                    class="btn btn-sm minus-btn"
                                    :disabled="passenger.children == 9"
                                    @click="addPassenger('plus', 'children')"
                                >
                                    <i class="fa fa-plus"></i>
                                </button>
                            </div>
                            <div class="col-12 gap"></div>
                            <div class="col-6">
                                <p class="text-dark mb-0 font-weight-bold">
                                    Infants
                                </p>
                                <p class="text-dark font-12 mb-0">
                                    &lt; 2 years
                                </p>
                            </div>
                            <div class="col-6">
                                <button 
                                    class="btn btn-sm minus-btn"
                                    :disabled="passenger.infants <= 0"
                                    @click="addPassenger('minus', 'infants')"
                                >
                                    <i class="fa fa-minus"></i>
                                </button>
                                <input 
                                    type="text" 
                                    class="passenger-inupt" 
                                    v-model="passenger.infants"
                                />
                                <button 
                                    class="btn btn-sm minus-btn"
                                    :disabled="passenger.infants == 9"
                                    @click="addPassenger('plus', 'infants')"
                                >
                                    <i class="fa fa-plus"></i>
                                </button>
                            </div>
                            <div class="col-12 gap"></div>
                            <div class="col-12">
                                <button 
                                    class="btn done-button btn-primary pull-right"
                                    @click="hidePassengerPanel()"
                                >
                                    DONE
                                </button>
                            </div>
                        </div>
                    </div>													
                </div>
            </div>

            <div class="col-lg-12 mt">
                <button 
                    class="btn btn-danger btn-block dropdown-toggle passenger-mobile" 
                    type="button" data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false"
                >
                    {{ type.title }}
                </button>
                <div class="dropdown-menu">
                    <a 
                        class="dropdown-item" 
                        href="javascript:void(0)"
                        v-for="(type, index) in typeData"
                        :key="index"
                        @click="changeTypeData(type)"
                    >
                        <i class="fa fa-check" v-if="type.selected"></i>
                        <span :style="'margin-left: ' +(type.selected ? '7px' : '20px')+';'">
                            {{ type.title }}
                        </span>
                    </a>
                </div>
            </div>

            <div class="col-lg-12">
                <button 
                    type="button" 
                    class="btn btn-search btn-block search-mobile-button"
                    @click="searchForFlight"
                >
                    Let's go
                    <i class="fab fa-accessible-icon"></i>
                </button>
            </div>

            <div class="col-xxs-12 col-xs-12 margin-top-20">
                <span class="pull-right">
                    <i class="fab fa-app-store"></i>
                    Affordability guaranteed
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import flatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'
    import moment from 'moment'
    import airports from '../../../../airports.json'
    import Autocomplete from 'vue3-autocomplete'
    import 'vue3-autocomplete/dist/vue3-autocomplete.css'
    import swal from 'sweetalert2';
    import countries from '../../../../countries.json'

    export default {
        name: 'Flights',
        components: {
            flatPickr,
            Autocomplete
        },
        data() {
            return {
                trip: {
                    title: 'Round Trip',
                    value: 2
                },
                type: {
                    title: 'Economy',
                    value: 'Y'
                },
                passenger: {
                    adult: 1,
                    children: 0,
                    infants: 0
                },
                record: {
                    departure: [],
                    destination: [],
                    date: [
                        moment().format('DD-MMM-YYYY')
                    ]
                },
                return_date: moment().add(3, 'day').format('DD-MMM-YYYY'),
                config: {
                    altFormat: 'M j, Y',
                    altInput: true,
                    dateFormat: 'd-M-Y',
                    minDate: "today",
                    // defaultDate: "today"   
                },
                returnConfig: {
                    altFormat: 'M j, Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    minDate: "today",  
                },
                mulitTrips: 1,
                airports: airports,
                countries: countries,
                searchAirports: [],
                searchReturnAirports: [],
                showDeptValue: [],
                showReturnValue: []
            }
        },
        computed: {
            ...mapGetters(['agencyCode', 'userId', 'userType']),
            getUrlFormat() {// get flight url
                let destination = this.record.destination;
                let departure = this.record.departure;
                let dates = this.record.date;
                let url = '/flight?';
                url += 'triptype=' + this.trip.value;
                let count = 1;
                for(let item of departure) {// set origin city search url
                    url += '&dep'+ count +'=' + item.dep;
                    url += '&dec'+ count +'=' + item.dec;
                    url += '&dct'+ count +'=' + item.dct;
                    url += '&dea'+ count +'=' + item.dea;
                    count++;
                }
                let count2 = 1;
                for(let item of destination) {
                    url += '&ret'+ count2 +'=' + item.ret;
                    url += '&rec'+ count2 +'=' + item.rec;
                    url += '&rct'+ count2 +'=' + item.rct;
                    url += '&rea'+ count2 +'=' + item.rea;
                    count2++;
                }
                let count3 = 1;
                for(let date of dates) {// set departure dates search url
                    url += '&dtt'+ count3 +'=' + date;
                    count3++;
                }
                for (let index = 0; index < this.record.departure.length; index++) {// set each trip value
                    url += '&cl' + (index + 1) +'=' + this.type.value; 
                }
                if(this.trip.value == 2) {// set return destination for round trip only
                    url += '&dtt2=' + this.return_date;// set return date search url
                    if(destination.length > 0) {// set return origin city value
                        url += '&dep2=' + destination[0].ret;
                        url += '&dec2=' + destination[0].rec;
                        url += '&dct2=' + destination[0].rct;
                        url += '&dea2=' + destination[0].rea;
                    }
                    if(departure.length > 0) {// set return destination city value
                        url += '&ret2=' + departure[0].dep;
                        url += '&rec2=' + departure[0].dec;
                        url += '&rct2=' + departure[0].dct;
                        url += '&rea2=' + departure[0].dea;
                    }
                }
                url += '&adult=' + this.passenger.adult;
                url += '&child=' + this.passenger.children;
                url += '&infant=' + this.passenger.infants;
                url += '&agencycode=' + this.agencyCode;
                url += '&direct=' + false;
                url += '&curr=NGN';
                url += '&tcl=Y';
                url += '&key=' + this.getKeyValue;
                url += '&journey=' + this.getJourney;
                url += '&user_id=' + this.userId;
                url += '&user_type=' + this.userType;
                url += '&langCode=EN';                
                return url;
            },
            getJourney() {
                let dest = this.record.destination;
                let dept = this.record.departure;
                if((dept.length > 0 && dept[0].dec == 'NG') && (dest.length > 0 && dest[0].rec == 'NG')) {
                    return 'Domestic';
                } else {
                    return 'International';
                }
            },
            getKeyValue() {// key value for flight url
                if(this.trip.value == 1) {
                    return 'IST';
                } else if(this.trip.value == 2) {
                    return 'IRT';
                } else {
                    return 'IMC';
                }
            },
            showRoundTripDate() {
                return this.trip.value == 2;
            },
            totalPassengers() {// cal. total passenger
                let total = 0;
                total += this.passenger.adult;
                total += this.passenger.children;
                total += this.passenger.infants;
                return total + (total > 1 ? ' Passengers' : ' Passenger');
            },
            tripData() {
                return [
                    { title: 'Round Trip', value: 2, selected: (this.trip.value == 2) },
                    { title: 'One Way', value: 1, selected: (this.trip.value == 1) },
                    { title: 'Multi City', value: 3, selected: (this.trip.value == 3) },
                ]
            },
            typeData() {
                return [
                    { title: 'Economy', value: 'Y', selected: (this.type.value == 'Y') },
                    { title: 'Premium Economy', value: 'W', selected: (this.type.value == 'W') },
                    { title: 'Business', value: 'C', selected: (this.type.value == 'C') },
                    { title: 'First Class', value: 'F', selected: (this.type.value == 'F') },
                ]
            }
        },
        methods: {
            searchForFlight() {
                let dest = this.record.destination;
                let dept = this.record.departure;
                if((dept.length > 0 && dept[0].dep) && (dest.length > 0 && dest[0].ret)) {
                    return window.location.href = this.getUrlFormat;
                } else {
                    let msg = 'You have not selected Orign / Destination City';
                    swal.fire("Invalid Inputs!", msg, "warning");
                }
            },
            hideTripValue(type, index) {
                this.searchAirports = [];
                this.searchReturnAirports = [];
                if(type == 'dept') {
                    this.showDeptValue.splice(index, 1);
                    this.record.departure.splice(index, 1);
                } else {
                    this.showReturnValue.splice(index, 1);
                    this.record.destination.splice(index, 1);
                }
            },
            selectedReturnAirport(e, index) {
                if(e && e.code) {
                    let getCountry = this.countries.filter(item => item.name.toLowerCase() == e.country.toLowerCase());
                    let country = getCountry && getCountry.length > 0 ? getCountry[0] : null;// get selected country
                    let inputs = { ret : e.code, rec : country.sortname, rct : e.state, rea : e.name };
                    this.record.destination[index] = inputs;
                    this.showReturnValue[index] = { value : e.code + ' - ' + e.state }; 
                } 
                this.searchReturnAirports = [];
            },
            selectedDeptAirport(e, index) {
                if(e && e.code) {
                    let getCountry = this.countries.filter(item => item.name.toLowerCase() == e.country.toLowerCase());
                    let country = getCountry && getCountry.length > 0 ? getCountry[0] : null;// get selected country                    
                    let inputs = { dep : e.code, dec : country.sortname, dct : e.state, dea : e.name };
                    this.record.departure[index] = inputs;
                    this.showDeptValue[index] = { value : e.code + ' - ' + e.state }; 
                } 
                this.searchAirports = [];             
            },
            getAirport(e) {// search for like city's airports
                this.searchAirports = [];
                if(e && e.length >= 3) {
                    let key = e.toLowerCase();
                    this.searchAirports = this.airports.filter(item => {
                        if(item.state) {
                            return item.state.toLowerCase().indexOf(key) !== -1 || 
                                (item.code.toLowerCase().indexOf(key) !== -1) || 
                                (item.city.toLowerCase().indexOf(key) !== -1)
                        } else {
                            return (item.city.toLowerCase().indexOf(key) !== -1) || 
                                (item.code.toLowerCase().indexOf(key) !== -1)
                        }
                        
                    });
                }
            },
            getReturnAirport(e) {// search for like city's airports
                this.searchReturnAirports = [];
                if(e && e.length >= 3) {
                    let key = e.toLowerCase();
                    this.searchReturnAirports = this.airports.filter(item => {
                        if(item.state) {
                            return item.state.toLowerCase().indexOf(key) !== -1 || 
                                (item.code.toLowerCase().indexOf(key) !== -1) || 
                                (item.city.toLowerCase().indexOf(key) !== -1)
                        } else {
                            return (item.city.toLowerCase().indexOf(key) !== -1) || 
                                (item.code.toLowerCase().indexOf(key) !== -1)
                        }
                        
                    });
                }
            },
            formattedDisplay(e) {
                return e.name + ' (' + e.code + ') - ' + e.state + ' , ' + e.country; 
            },
            addAnotherTrip(status) {
                if(status) {
                    this.mulitTrips++;
                    return this.record.date.push(moment().format('DD-MMM-YYYY'));
                } else {
                    this.mulitTrips--;
                    return this.record.date.splice(this.mulitTrips, 1);
                }
            },
            changeTripData(data) {// change trip data
                this.mulitTrips = 1;
                if(data && data.title) {
                    this.trip = { title: data.title, value: data.value }
                }
            },
            changeTypeData(data) {// change type data
                if(data && data.title) {
                    this.type = { title: data.title, value: data.value }
                }
            },
            hidePassengerPanel() {
                $('.dropdown.show,.dropdown-menu.show').removeClass('show');
            },
            addPassenger(status, type) {
                if(type == 'adult') {// update adult passenger
                    if(status == 'plus') {
                        (this.passenger.adult <= 9) ? this.passenger.adult++ : false;
                    } else {
                        (this.passenger.adult > 1) ? this.passenger.adult-- : false;
                    }
                } else if(type == 'children') {// update adult passenger
                    if(status == 'plus') {
                        (this.passenger.children <= 9) ? this.passenger.children++ : false;
                    } else {
                        (this.passenger.children > 0) ? this.passenger.children-- : false;
                    }
                } else {
                    if(status == 'plus') {
                        (this.passenger.infants <= 9) ? this.passenger.infants++ : false;
                    } else {
                        (this.passenger.infants > 0) ? this.passenger.infants-- : false;
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>