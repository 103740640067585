<template>
    <div class="desc">
        <div class="container">
            <div class="row">
                <div class="col-sm-5 col-md-5">
                    <div class="tabulation animate-box">
    
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs" role="tablist">
                            <li role="presentation" class="active" id="flight-panel">
                                    <a href="#flights" aria-controls="flights" class="text-white"
                                        role="tab" data-toggle="tab" id="flight-button"
                                        @click="getActivePanel('flight', 'hotel')"
                                    >
                                        <i class="fa fa-plane"></i>
                                        Flights
                                    </a>
                            </li>
                            <!-- <li role="presentation" id="hotel-panel">
                                    <a class="text-white" href="#hotels" id="hotel-button"
                                        @click="getActivePanel('hotel', 'flight')"
                                        aria-controls="hotels" role="tab" data-toggle="tab">
                                        <i class="fas fa-hotel"></i>
                                        Hotels
                                    </a>
                            </li> -->
                            <!-- <li role="presentation">
                                <a href="#cars" aria-controls="packages" role="tab" data-toggle="tab">Cars</a>
                            </li> -->
                        </ul>
    
                        <!-- Tab panes -->
                        <div class="tab-content">
                            
                            <Flights />
                            
                            <Hotels />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Flights from './Panels/Flights.vue'
    import Hotels from './Panels/Hotels.vue'

    export default {
        name: 'FlightPanel',
        components: {
            Flights,
            Hotels
        },
        methods: {
            getActivePanel(current, other) {// change the background color of the active panel
                $(`#${current}-panel`).attr('class', 'active');
                $(`#${other}-panel`).attr('class', '');
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>