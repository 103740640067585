import { createRouter, createWebHistory } from 'vue-router'
import Homepage from './components/pages/Homepage'
import FlightSearchPage from './components/pages/FlightSearchPage'
import OtherContentPage from './components/pages/OtherContentPage'
import MenuPage from './components/pages/MenuPage'
import OtherContentCategory from './components/pages/OtherContentCategory'
import HotelSearchPage from './components/pages/HotelSearchPage'
import MainMenuPage from './components/pages/MainMenuPage'
import SubMenuPage from './components/pages/SubMenuPage'
import BlogGridPage from './components/pages/BlogGridPage'
import OtherContentSinglePage from './components/pages/OtherContentSinglePage'
import ContactPage from './components/pages/ContactPage'
import PaymentPage from './components/pages/PaymentPage'
import SubMenuRoutePage from './components/pages/SubMenuRoutePage'
import MenuRoutePage from './components/pages/MenuRoutePage'

const routes = [
    { path: '/', name: 'Homepage', component: Homepage },
    { path: '/flight', name: 'FlightSearchPage', component: FlightSearchPage },
    { path: '/hotel/search', name: 'HotelSearchPage', component: HotelSearchPage },
    { path: '/pages/:category/:slug', name: 'OtherContentPage', component: OtherContentPage },
    { path: '/menu/:category/:slug', name: 'MenuPage', component: MenuPage },
    { path: '/category/:slug', name: 'OtherContentCategory', component: OtherContentCategory },
    { path: '/vision', name: 'vision', component: SubMenuPage },
    { path: '/explore', name: 'explore', component: MainMenuPage },
    { path: '/blog', name: 'blog', component: BlogGridPage },
    { path: '/privacy-policy', name: 'privacy-policy', component: OtherContentSinglePage },
    { path: '/contact', name: 'contact', component: ContactPage },
    { path: '/payments', name: 'payments', component: PaymentPage },
    { path: '/:menu/:slug', name: 'sub-menu-page', component: SubMenuRoutePage },
    { path: '/:slug', name: 'menu-page', component: MenuRoutePage },



    
    { path: '/car',  },    
    
    { path: '/about-us', },
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})
  


export default router;
